import { Badge, Box, Notification, useToast } from '@iheartradio/web.companion';

export const DevelopmentBanner = () => {
  const toast = useToast();

  return (
    <Box
      cursor="pointer"
      onClick={() => {
        toast(dismiss => (
          <Notification
            kind="info"
            onDismiss={dismiss}
            title="Development Mode?"
          >
            AMP config is set to use only <code>clientEndpoint</code> and any
            network calls that require the VPN will exit early. Use the
            following command to run in production mode:
            <br />
            <code>
              CONFIG_ENV=production pnpm -F @iheartradio/web.account dev
            </code>
          </Notification>
        ));
      }}
      position="fixed"
      right="0"
      role="marquee"
      top="0"
    >
      <Badge backgroundColor="$brand-red">Dev Mode</Badge>
    </Box>
  );
};
