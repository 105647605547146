import { type Analytics, eventType } from '@iheartradio/web.analytics';
import deepmerge from 'deepmerge';
import { useMemo } from 'react';

import { useAnalytics } from '~app/analytics';
import { useConfig } from '~app/contexts/config';

export async function sendIglooEvent(
  iglooUrl: string | URL,
  payload: Analytics.Event & { view: Record<string, unknown> },
) {
  const { data, type, ...rest } = payload;

  return await fetch(iglooUrl, {
    method: 'POST',
    body: JSON.stringify({
      action: type,
      ...deepmerge.all([data ?? {}, rest ?? {}]),
      ...(type === eventType.enum.PageView
        ? {
            view: { ...deepmerge.all([data?.view ?? {}, rest?.view ?? {}]) },
          }
        : {}),
    }),
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export function Igloo() {
  const config = useConfig();
  const analytics = useAnalytics();

  useMemo(() => {
    const unsubscribe = analytics.subscribe({
      async track(payload) {
        if (!config.urls.iglooUrl) {
          console.error(`'config.urls.iglooUrl' is not set`);
          return;
        }

        return await sendIglooEvent(config.urls.iglooUrl, payload);
      },
    });
    return unsubscribe;
  }, [analytics, config.urls?.iglooUrl]);

  return null;
}
