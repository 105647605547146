import {
  Box,
  core,
  Reset,
  ThemeContext,
  ToastProvider,
} from '@iheartradio/web.companion';
import { type BaseConfig } from '@iheartradio/web.config';
import {
  type DocumentSharedProps,
  ClientHintCheck,
  getHints,
  METADATA_APP_NAME,
  METADATA_DEFAULT_IMAGE,
  METADATA_DOMAIN,
  METADATA_GLOBAL_DESCRIPTION,
  METADATA_GLOBAL_KEYWORDS,
  METADATA_GLOBAL_TITLE,
  METADATA_OPENGRAPH_TYPES,
  METADATA_TWITTER_CARDS,
  METADATA_TWITTER_HANDLE,
  setBasicMetadata,
} from '@iheartradio/web.remix-shared';
import {
  useTheme,
  useTrackVisibilityChange,
} from '@iheartradio/web.remix-shared/react';
import { isNotBlank, isNotNil } from '@iheartradio/web.utilities';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteLoaderData,
} from '@remix-run/react';
import {
  type LoaderFunctionArgs,
  type SerializeFrom,
  type ServerRuntimeMetaFunction,
  json,
} from '@remix-run/server-runtime';
import { useContext, useEffect, useRef } from 'react';

import { DevelopmentBanner } from '~app/components/development-banner';
import { AppErrorBoundary } from '~app/components/error/app-error-boundary';
import { getConfigFromRequest } from '~app/config.server';
import { ConfigProvider } from '~app/contexts/config';
import { UserContext } from '~app/contexts/user';
import {
  getCookieOptions,
  LoginRegistrationCookieJar,
  NoCacheHeaders,
  ThemeCookie,
} from '~app/lib/remix-shared-node.server';
import {
  getDomainUrl,
  getGeolocationForRequest,
  getThemeFromRequest,
} from '~app/lib/remix-shared-server.server';
import { getIsDevelopment } from '~app/utilities/utilities.server';

import { Analytics, useAnalytics } from './analytics';
import ClientStyleContext from './contexts/client-style';
import { getServerContext } from './get-server-context.server';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const config = getConfigFromRequest(request);
  const { user, authEvent } = await getServerContext(request);
  const { cookieDomain, isSecure } = getCookieOptions(request);

  const { CONFIG_ENV, npm_package_version, SHORT_COMMIT } = process.env;

  const isDevelopment = getIsDevelopment();

  const headers = new Headers(NoCacheHeaders);

  if (isNotBlank(authEvent)) {
    headers.append(
      'Set-Cookie',
      await LoginRegistrationCookieJar.serialize(
        {},
        {
          expires: new Date(0),
          domain: cookieDomain,
          secure: isSecure,
          httpOnly: true,
        },
      ),
    );
  }

  const hints = getHints(request);

  return json(
    {
      appVersion: npm_package_version ?? '',
      authEvent,
      config,
      geolocation: getGeolocationForRequest(request),
      isDevelopment,
      user,
      CONFIG_ENV,
      SHORT_COMMIT,
      // This block is what the new `useTheme` hook uses to determine the correct theme from browser
      // hints or the explicit theme that the user has chosen
      requestInfo: {
        hints: {
          ...hints,
          theme: (await getThemeFromRequest(request)) ?? hints.theme,
        },
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: await ThemeCookie.parse(request.headers.get('Cookie')),
        },
      },
    },
    { headers },
  );
};

export type RootLoader = typeof loader;
export type RootLoaderData = SerializeFrom<RootLoader>;

export const useRootLoaderData = () => {
  const loaderData = useRouteLoaderData<RootLoader>('root');
  if (!loaderData) {
    throw new Error('Could not get root loader data');
  }
  return loaderData;
};

export const meta: ServerRuntimeMetaFunction<typeof loader> = ({ data }) => {
  const { config } = data ?? {};

  return [
    ...setBasicMetadata({
      title: METADATA_GLOBAL_TITLE,
      description: METADATA_GLOBAL_DESCRIPTION,
      keywords: METADATA_GLOBAL_KEYWORDS,
      image: METADATA_DEFAULT_IMAGE,
      type: METADATA_OPENGRAPH_TYPES.Website,
      card: METADATA_TWITTER_CARDS.Summary,
      url: 'https://account.iheart.com', // TODO: app url generation should use a common function
    }),
    { content: METADATA_APP_NAME, property: 'og:site_name' },
    { content: METADATA_DOMAIN, name: 'twitter:domain' },
    { content: METADATA_TWITTER_HANDLE, name: 'twitter:creator' },
    { content: METADATA_TWITTER_HANDLE, name: 'twitter:site' },
    { content: METADATA_APP_NAME, name: 'twitter:app:name:iphone' },
    { content: METADATA_APP_NAME, name: 'twitter:app:name:ipad' },
    { content: METADATA_APP_NAME, name: 'twitter:app:name:googleplay' },
    { content: METADATA_APP_NAME, name: 'al:android:app_name' },
    { content: METADATA_APP_NAME, name: 'al:ios:app_name' },
    { content: core.colors['brand-red'], name: 'theme-color' },
    ...(config
      ? [
          config?.sdks?.facebook?.appId
            ? { content: config.sdks.facebook.appId, property: 'fb:app_id' }
            : null,
          config?.sdks?.facebook?.pages
            ? { content: config.sdks.facebook.pages, property: 'fb:pages' }
            : null,
          { content: config.app.appleId, name: 'twitter:app:id:iphone' },
          { content: config.app.appleId, name: 'twitter:app:id:ipad' },
          { content: config.app.appleId, name: 'al:ios:app_store_id' },
          {
            content: config.app.googlePlayId,
            name: 'twitter:app:id:googleplay',
          },
          { content: config.app.googlePlayId, name: 'al:android:package' },
        ].filter(isNotNil)
      : []),
  ];
};

export default function App() {
  const { authEvent, config, isDevelopment, user, CONFIG_ENV, SHORT_COMMIT } =
    useLoaderData<typeof loader>();

  const theme = useTheme();

  const authEventRef = useRef<string>();

  const analytics = useAnalytics();

  useTrackVisibilityChange(analytics);

  if (isNotBlank(authEvent) && authEventRef.current !== authEvent.type) {
    authEventRef.current = authEvent.type;
    analytics.track(authEvent);
  } else if (!isNotBlank(authEvent)) {
    authEventRef.current = '';
  }

  return (
    <Document
      config={config}
      CONFIG_ENV={CONFIG_ENV}
      isDevelopment={isDevelopment}
      SHORT_COMMIT={SHORT_COMMIT}
      theme={theme}
    >
      <UserContext.Provider value={user}>
        <ConfigProvider value={config}>
          <Analytics user={user} />
          <ToastProvider>
            <Outlet />
          </ToastProvider>
        </ConfigProvider>
      </UserContext.Provider>
    </Document>
  );
}

export interface DocumentProps extends DocumentSharedProps {
  isDevelopment?: boolean;
  config?: BaseConfig;
  CONFIG_ENV?: string;
  SHORT_COMMIT?: string;
}

const Document = (props: DocumentProps) => {
  const {
    config,
    children,
    isDevelopment = false,
    CONFIG_ENV,
    theme,
    SHORT_COMMIT,
  } = props;

  const clientStyleData = useContext(ClientStyleContext);

  // Only executed on client
  useEffect(() => {
    // Reset cache to re-apply global styles
    clientStyleData.reset();
  }, [clientStyleData]);

  return (
    <html lang="en">
      <head>
        <ClientHintCheck />
        <Meta />
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta content="on" httpEquiv="x-dns-prefetch-control" />
        <meta content="yes" name="mobile-web-app-capable" />
        <style
          dangerouslySetInnerHTML={{ __html: clientStyleData.sheet }}
          id="stitches"
          suppressHydrationWarning
        />
        <script
          async
          id="_cls_detector"
          src={
            CONFIG_ENV === 'production'
              ? 'https://cdn.gbqofs.com/iheartmedia/web.listen/p/detector-dom.min.js'
              : 'https://cdn.gbqofs.com/iheartmedia/web.listen/u/detector-dom.min.js'
          }
        />
        <Links />
        <script async src="https://js.recurly.com/v4/recurly.js"></script>
        <script
          src={`https://www.google.com/recaptcha/enterprise.js?render=${config?.sdks.googleRecaptcha.v3SiteKey}`}
        ></script>
        <link href="/recurly.css" rel="stylesheet"></link>
      </head>
      <ThemeContext.Provider value={theme}>
        <Box
          as="body"
          backgroundColor={{ dark: '$brand-black', light: '$gray-100' }}
          data-theme={theme}
          data-version={SHORT_COMMIT}
        >
          <Reset />
          <div id="fb-root"></div>
          {isDevelopment ? <DevelopmentBanner /> : null}
          {children}
          <ScrollRestoration />
          <Scripts />
        </Box>
      </ThemeContext.Provider>
    </html>
  );
};

export const ErrorBoundary = () => <AppErrorBoundary document={Document} />;
